<template>
  <trac-loading v-if="isLoading"></trac-loading>
  <main v-else>
    <div class="overflow-scroll mb-10" style="max-height: 600px">
      <table class="shadow-lg bg-white w-full relative border-collapse">
        <tr>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Business Name
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            ID
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            User ID
          </th>

          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Category
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Email
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Phone Number
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Pricing Group
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Layalty Active
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Sell By Online
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Sell By Invoice
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Sell By POS
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Postal Code
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Street
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            City
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            State
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Country
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Created At
          </th>
          <th
            class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
          >
            Aggregator
          </th>
          <th
            class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
          >
            Aggregator ID
          </th>

          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Suspended
          </th>
        </tr>
        <tr v-for="(business, i) in filteredDataset" :key="i">
          <td class="border px-8 py-3 text-xs capitalize">
            {{ business.name }}
          </td>
          <td class="border px-8 py-3 text-xs capitalize">
            {{ business._id }}
          </td>
          <td class="border px-8 py-3 text-xs capitalize">
            {{ business.user_id }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ business.category }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs lowercase">
            {{ business.email }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ business.phone }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ business.pricing_group }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ business.loyalty_active }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ business.business_use_case.sell_by_invoice }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ business.business_use_case.sell_by_online }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ business.business_use_case.sell_by_pos }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ business.address ? business.address.postal_code : '' }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ business.address ? business.address.street : '' }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ business.address ? business.address.city : '' }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ business.address ? business.address.state : '' }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ business.address ? business.address.country : '' }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ moment(business.created_at).format('DD MMM YYYY - h:mm:ss a') }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ business.is_aggregator }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ business.aggregator_id }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            >>>>>>> f7b8fb67c0a897416c7499dd716661c12099383a
            {{ business.suspended }}
          </td>
        </tr>
      </table>
    </div>
    <div class="">
      <button
        @click="prevPage(currentPage)"
        :disabled="currentPage === 1 ? isDisabled : !isDisabled"
        class="
                    
                    uppercase
                    shadow
                    ml-3
                    hover:bg-blue-600
                    
                    focus:shadow-outline
                    focus:outline-none
                    text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
        :class="
          currentPage === 1 ? 'bg-blue-50 text-black' : 'bg-blue-900 text-white'
        "
      >
        &laquo; Prev
      </button>

      <button
        v-if="businesses.length > 99"
        @click="nextPage(currentPage)"
        class="
                    float-right
                    uppercase
                    shadow
                    ml-3
                    hover:bg-blue-600
                    bg-blue-900
                    focus:shadow-outline
                    focus:outline-none
                    text-white text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
      >
        Next &raquo;
      </button>
    </div>
  </main>
</template>

<script>
import moment from 'moment';

export default {
  props: ['res', 'searchDataset'],
  data() {
    return {
      moment,
      businesses: null,
      currentPage: 1,
      isDisabled: true,
      isLoading: false,
      businesses: [],
    };
  },
  computed: {
    filteredDataset() {
      return this.businesses.filter((business) =>
        business.name.toLowerCase().includes(this.searchDataset.toLowerCase())
      );
    },
  },
  created() {
    const data = this.res || [];
    if (data) {
      this.businesses = data.businesses.sort((a, b) =>
        a.name.trim().toLowerCase() > b.name.trim().toLowerCase() ? 1 : -1
      );
    }
    this.isLoading = false;
  },
  methods: {
    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        'FETCH_PAGINATED_DATASET_BUSINESSES',
        currPage - 1
      );
      if (res.status) {
        this.businesses = res.data.businesses.sort((a, b) =>
          a.name.trim().toLowerCase() > b.name.trim().toLowerCase() ? 1 : -1
        );
        this.isLoading = false;
        this.currentPage -= 1;
      }
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        'FETCH_PAGINATED_DATASET_BUSINESSES',
        currPage + 1
      );
      if (res.status) {
        this.businesses = res.data.businesses.sort((a, b) =>
          a.name.trim().toLowerCase() > b.name.trim().toLowerCase() ? 1 : -1
        );
        this.isLoading = false;
        this.currentPage += 1;
      }
    },
  },
};
</script>

<style scoped>
table tr th {
  background: #f0f6fd;
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
</style>
